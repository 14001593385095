
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from '@/services/StorageServices';
import SwiperSlides from '@/components/swiperSlides.vue';
import store from '@/store';
import * as VM from '@/viewModel';

@Options({
    components: {
        SwiperSlides
    }
})
export default class Welcome extends Vue {

    languages: string[] = [];

    created() {
        this.languages = VM.getLanguages();
    }

    get selectedLanguage() {
        return store.state.preferredCulture;
    }

    setLanguage(language: string) {
        StorageServices.setPreferredCulture("\"" + language + "\"");
        store.state.preferredCulture = language;
    }

    navTo(path){
        this.$router.push({
            path,
            query: this.$route.query
        })
    }
}

